<script></script>

<h1><i class="fas fa-share-square"></i>&nbsp;&nbsp;ShareX</h1>

<main>
    ShareX custom uploader config: <a href="sharex.sxcu"
        >{window.origin}/sharex.sxcu</a
    >
</main>

<style type="text/scss">
    @import "src/scss/variables";

    h1,
    a {
        color: $accent;
    }

    main {
        color: #fff;
    }
</style>
